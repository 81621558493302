import React, { Component } from 'react'
import SbEditable from 'storyblok-react'
import { render } from 'storyblok-rich-text-react-renderer'
import gridOfDots from "../images/grid-of-dots.svg"
import dashedLineVert from "../images/dashed-line-vert.svg"
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { CSSPlugin } from "gsap/CSSPlugin"
import { gsap } from 'gsap'
import CloudinaryVideo from "./cloudinaryvideoembed"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Scrollbar from 'smooth-scrollbar'
import { isMobile, isTablet } from "react-device-detect"
// import ScaleSpeedPlugin from './ScaleSpeedPlugin'

// const Hero = ({blok}) => {
class Hero extends Component {
  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(CSSRulePlugin);
    gsap.registerPlugin(CSSPlugin);
    const scroller = document.querySelector('#scroller')

    if(scroller && !isMobile && !isTablet) {
      const bodyScrollBar = Scrollbar.init(scroller, {
        delegateTo: document,
        alwaysShowTracks: true,
        syncCallbacks: true,
        // plugins: {
        //   scaleSpeed: {
        //     speed: 15,
        //   },
        // },
      })

      ScrollTrigger.scrollerProxy("#scroller", {
        scrollTop(value) {
          if (arguments.length) {
            bodyScrollBar.scrollTop = value;
          }
          return bodyScrollBar.scrollTop;
        }
      });
      bodyScrollBar.addListener(ScrollTrigger.update);

      ScrollTrigger.defaults({ scroller: scroller });





      // gsap.to('section.category-intro h1', {
      //   y: -100,
      //   opacity: 0,
      //   scrollTrigger: {
      //     trigger: "section.category-intro",
      //     start: "top top",
      //     end: () => "+=" + window.innerHeight*0.5,
      //     scrub: true,
      //   }
      // });
      // gsap.to('section.category-intro .intro', {
      //   y: 100,
      //   opacity: 0,
      //   scrollTrigger: {
      //     trigger: "section.category-intro",
      //     start: "top top",
      //     end: () => "+=" + window.innerHeight*0.75,
      //     scrub: true,
      //   }
      // });
    }

    let animTimeline = gsap.timeline();

    let rule = CSSRulePlugin.getRule(".crop-reveal-text:before");

    animTimeline.to('.back', {
      opacity: 0.25,
      duration: 0.75,
      ease: "power4.out"
    }, 1).staggerTo('.fore', 1.25, {
      ease: "power4.inOut",
      clipPath:"inset(0% 0 0 0)"
    }, 0.2)




    // gsap.set('.category-intro__container', {
    //   opacity: 0
    // })

    // gsap.from('.title', { opacity: 0, x: 50, duration: 1.4, delay: 1 })
    // gsap.from('.intro', { opacity: 0, duration: 1.4, delay: 1.3 })

    // gsap.to(".scroll-duration-indicator", {
    //   scaleX: 1,
    //   scrollTrigger: {
    //     trigger: ".zip-three-sixty-wrapper",
    //     start: "top 0%",
    //     end: "+=4000", // slow down the time it takes to complete the anim
    //     // markers: true,
    //     // pin: true,
    //     // pinSpacing: false,
    //     scrub: true,
    //     // toggleActions: "play reverse play reverse",
    //   },
    // });
  }
  render() {
    const blok = this.props.blok
    return (
      <SbEditable content={blok}>
        <section className={ (blok.IntroVideo || blok.IntroImage || blok.FullScreen) ? 'category-intro category-intro--full-screen' : 'category-intro' }>
          <div className="container category-intro__container">
            <div className="title">
              {(blok.TitleBrand) ? (
                <h1><span className="back">{blok.TitleBrand}</span><span className="fore">{blok.TitleBrand}</span></h1>
              ) : '' }
              <h1 className={(blok.Title.length > 35 || blok.SmallTitle) ? 'alt' : ''}><span className="back">{blok.Title}</span><span className="fore">{blok.Title}</span></h1>

            </div>
            <div className="flex intro">
              <div className={(blok.LargeIntro) ? 'category-intro__intro category-intro__intro--large' : 'category-intro__intro' }>
                {render(blok.Intro)}
              </div>
            </div>

            {
              (blok.IntroVideo) ? (
                <div className="category-intro__media category-intro__media--video">
                  <CloudinaryVideo
                    publicId={`ykk/${blok.IntroVideo}`}
                    fadeInOut={true}
                    class_list="cover-bg"
                    autoPlay={true}
                  />
                </div>
              ): ''
            }


            {
              (blok.IntroImage) ? (
                <div className="category-intro__media category-intro__media--image">
                  <Img loading="eager" fluid={
                    getFluidGatsbyImage(blok.IntroImage, {
                      maxWidth: 900
                    })
                  } />
                </div>
              ) : ''
            }

          </div>
          <img alt="Dots icon" aria-hidden={true} className="grid-of-dots category-intro__grid-of-dots" src={gridOfDots} />
          <img alt="Dots icon" aria-hidden={true} className="dashed-line-vert category-intro__dashed-line-vert" src={dashedLineVert} />
        </section>
      </SbEditable>
    )
  }
}

//
// (blok.IntroImage) ? (
//   <div className="category-intro__media category-intro__media--image">
//     <Img loading="eager" fluid={
//       getFluidGatsbyImage(blok.IntroImage, {
//         maxWidth: 900
//       })
//     }
//   </div>
// ) : ''

export default Hero
