import React, { Component } from 'react'
import DynamicComponent from "./DynamicComponent"
import SbEditable from 'storyblok-react'
import CloudinaryVideo from './cloudinaryvideoembed'
import Footer from './Footer'
import { Draggable } from "gsap/Draggable"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { InertiaPlugin } from "gsap/InertiaPlugin"
import Scrollbar from 'smooth-scrollbar'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import zipIcon from "../images/zip-icon-real.png"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import { Video, Transformation } from 'cloudinary-react'
import { isMobile, isTablet } from "react-device-detect"

class Category extends Component {
  constructor(props) {
    super(props)
    this.scrollBarRef = React.createRef();
    this.scrollBarWrapper = React.createRef();
  }

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(InertiaPlugin);
    gsap.registerPlugin(Draggable);
    gsap.registerPlugin(ScrollToPlugin);
    gsap.registerPlugin(Draggable);
    // const scroller = this.scrollerRef.current
    const scroller = document.querySelector('#scroller')

    let handler = document.querySelector("#handler"),
    barLength, maxScroll, trigger, draggable;

    barLength = 400

    if(!isMobile && !isTablet) {
      const bodyScrollBar = Scrollbar.init(scroller, { delegateTo: document, alwaysShowTracks: true, syncCallbacks: true })

      ScrollTrigger.scrollerProxy("#scroller", {
        scrollTop(value) {
          if (arguments.length) {
            bodyScrollBar.scrollTop = value;
          }
          return bodyScrollBar.scrollTop;
        }
      });
      bodyScrollBar.addListener(ScrollTrigger.update);
      ScrollTrigger.defaults({ scroller: scroller });

      bodyScrollBar.addListener(({ offset }) => {
        gsap.set(handler, {y: barLength * ( offset.y ) / (maxScroll-820)});
      });
      maxScroll = bodyScrollBar.size.content.height

      gsap.utils.toArray('.grid-of-dots').forEach(gridOfDotsEl => {
        gsap.to(gridOfDotsEl, {
          y: -250,
          scrollTrigger: {
            trigger: gridOfDotsEl,
            start: "top bottom",
            end: () => "+=" + window.innerHeight*2,
            scrub: true,
          },
        });
      });



      let scrollerProgressAnim = gsap.to('.draggable-progress', {
        scaleY: 0,
        scrollTrigger: {
          trigger: ".draggable-progress",
          start: "top 0%",
          end: "+="+maxScroll, // slow down the time it takes to complete the anim
          scrub: true,
        }
      });

      var drag = Draggable.create(handler, {
        type: "y",
        bounds: {minY: 1, maxY: 399},
        // inertia: true,
        onDrag: function() {
          // zipScroll.scrollTrigger.scroll( zipScroll.scrollTrigger.start + (this.y / barLength * maxScroll) )
          scrollerProgressAnim.scrollTrigger.scroll( this.y / barLength * maxScroll )
        },
        onDragStart: function() {
          this.target.classList.add('dragged')
        }
      })[0];




      gsap.utils.toArray(".row-text").forEach((textEl, i) => {
        gsap.from(textEl, {
          // clipPath:"inset(0% 0%)",
          opacity: 0,
          scrollTrigger: {
            trigger: textEl,
            start: "top 75%",
            // pin: true,
            end: () => "+=100",
            scrub: true,
            // markers: true,
            toggleActions: "play reverse play reverse"
          },
        });
      });

      gsap.utils.toArray(".row-title").forEach((titleEl, i) => {
        gsap.from(titleEl, {
          // clipPath:"inset(0% 0%)",
          opacity: 0,
          scrollTrigger: {
            trigger: titleEl,
            start: "top 50%",
            // pin: true,
            // pinSpacing: false,
            end: () => "+=150",
            scrub: true,
            // markers: true,
            toggleActions: "play reverse play reverse"
          },
        });
      });

      gsap.utils.toArray(".row-bullet-point").forEach((bullet, i) => {
        gsap.from(bullet, {
          clipPath:"inset(0% 100% 0% 0%)",
          opacity: 0,
          scrollTrigger: {
            trigger: bullet,
            start: "top 60%",
            // pin: true,
            // pinSpacing: false,
            end: () => "+=25",
            scrub: true,
            // markers: true,
            toggleActions: "play reverse play reverse"
          },
        });

      })

      gsap.utils.toArray(".product-gif").forEach((gif, i) => {
        gsap.set(gif, {clipPath:"inset(50% 0.5%)"});

        gsap.to(gif, {
          clipPath:"inset(0% 0.5%)",
          scrollTrigger: {
            trigger: gif,
            start: "top 45%",
            end: () => "+=100",
            scrub: true,
            // markers: true,
            toggleActions: "play reverse play reverse"
          },
        });

      })

    } else {
      maxScroll = window.innerHeight
      document.querySelector(".bar").style.display = "none"
    }



    gsap.to('.bg-video', {
      opacity: 0,
      scrollTrigger: {
        trigger: '.bg-video',
        start: "bottom 50%",
        // end: "+=10",
        end: () => "+=" + window.innerHeight*1.25,
        scrub: true,
        toggleActions: "play reverse play reverse"
      },
    });

    gsap.set('.outro-bg', {opacity: 0});
    gsap.to('.outro-bg', {
      opacity: 0.2,
      scrollTrigger: {
        trigger: '.outro-i',
        start: "top top",
        end: () => "+="+document.querySelector('.outro-ii').offsetHeight + document.querySelector('.products-list').offsetHeight,
        pin: true,
        pinSpacing: false,
      },
    });



    gsap.to('.outro-fore', {
      clipPath:"inset(15% 5%)",
      scrollTrigger: {
        trigger: '.outro-fore',
        start: "top top",
        end: () => "+=500",
        scrub: true,
      },
    });

    gsap.from('.outro-fore', {
      clipPath:"inset(15% 5%)",
      scrollTrigger: {
        trigger: '.products-list',
        start: "top bottom",
        // pin: true,
        end: () => "+=250",
        scrub: true,
      },
    });
    gsap.set('.outro-fore', {clipPath:"inset(50% 5%)"});



  }

  render() {
    const blok = this.props.blok
    const content =
      blok.LayoutBuilder &&
      blok.LayoutBuilder.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid} loadingComplete={() => this.loadingComplete()} />)
    return (
    <SbEditable content={blok} key={blok._uid}>
      {(blok.bg_cloudinary_id) ?
        <div className="bg-video">
          <CloudinaryVideo
            publicId={`ykk/${blok.bg_cloudinary_id}`}
            class_list="cover-bg"
            autoPlay={true}
          />
        </div>
        : ''}

        <div className="zip-three-sixty-wrapper__scroll-handler bar zip-three-sixty-wrapper__scroll-handler--active" ref={this.scrollBarWrapper}>
          <div id="handler" className="active" ref={this.scrollBarRef}>
            <img src={zipIcon} alt="zip icon" />
          </div>
          <div className="draggable-progress"></div>
        </div>

      <div id="scroller">
        {
          blok.LayoutBuilder && blok.LayoutBuilder.map((childBlok) => {
            if(childBlok.component === "Hero") {
              return (
                <DynamicComponent blok={childBlok} key={childBlok._uid} loadingComplete={() => this.loadingComplete()} />
              )
            }
          })
        }

        <section className="outro-video">
          <CloudinaryVideo
            publicId={`ykk/${blok.OverviewVideo}`}
            disableGif={true}
            class_list="cover-bg"
            autoPlay={false}
            clickToPlay={true}
          />
        </section>

        <section className="outro-i">

          <Img loading="eager" className="cover-bg outro-bg" fluid={
            getFluidGatsbyImage(blok.DetailsBG, {
              maxWidth: 2000
            })
          } />

          <div className="outro-i__one outro-fore">
            <Img loading="eager" className="cover-bg" fluid={
              getFluidGatsbyImage(blok.DetailsBG, {
                maxWidth: 2000
              })
            } />
          </div>

        </section>
        <section className="outro-ii outro-panel">

          <div className="container container--x-slim">
            {
              blok.Details && blok.Details.map((childBlok) => {
                // console.log(childBlok);
                return (
                  <DynamicComponent blok={childBlok} key={childBlok._uid} />
                )
              })
            }
          </div>

        </section>

        {
          blok.LayoutBuilder && blok.LayoutBuilder.map((childBlok) => {
            if(childBlok.component === "Products") {
              return (
                <DynamicComponent blok={childBlok} key={childBlok._uid} />
              )
            }
          })
        }

        <Footer />
      </div>
    </SbEditable>
  )
  }
}

export default Category
