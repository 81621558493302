import React, { Component } from 'react'
import SbEditable from 'storyblok-react'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import Link from 'gatsby-link'
import Scrollbar from 'smooth-scrollbar'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { isMobile, isTablet } from "react-device-detect"

class Products extends Component {

  componentDidMount() {
    gsap.registerPlugin(ScrollTrigger);
    const scroller = document.querySelector('#scroller')
    if(!isMobile && !isTablet) {
      const bodyScrollBar = Scrollbar.init(scroller, { delegateTo: document, alwaysShowTracks: true, syncCallbacks: true })

      ScrollTrigger.scrollerProxy("#scroller", {
        scrollTop(value) {
          if (arguments.length) {
            bodyScrollBar.scrollTop = value;
          }
          return bodyScrollBar.scrollTop;
        }
      });
      bodyScrollBar.addListener(ScrollTrigger.update);

      ScrollTrigger.defaults({ scroller: scroller });

      gsap.utils.toArray('.product-link').forEach(productLink => {
        gsap.from(productLink, {
          y: 125,
          opacity: 0,
          scrollTrigger: {
            trigger: productLink,
            start: "top 60%",
            toggleActions: "play reverse play reverse"
          },
        });
      });

      gsap.utils.toArray('.product-link img').forEach(productLinkImg => {
        gsap.from(productLinkImg, {
          clipPath:"inset(100% 0 0 0)",
          scrollTrigger: {
            trigger: productLinkImg,
            start: "top 80%",
            toggleActions: "play reverse play reverse"
          },
        });
      });


      gsap.utils.toArray('.product-link .product-link__product-name').forEach(productLinkTitle => {
        gsap.from(productLinkTitle, {
          clipPath:"inset(0 100% 0 0)",
          scrollTrigger: {
            trigger: productLinkTitle,
            start: "top 90%",
            toggleActions: "play reverse play reverse"
          },
        });
      });
    }


  }

  render() {
    const blok = this.props.blok
    const title = this.props.title
    console.log(blok)

    return (
      <SbEditable content={blok}>
        <section className="products-list">
          <div className="container container--slim">
            <div className="products-list__title">
              <p className="h2">{ (title) ? title : 'Products' }</p>
            </div>
            <div className="products-list__grid">
            {blok.Products &&
              blok.Products.map((product, index) => (
                <SbEditable content={product} key={product._uid}>
                  <div className={ (blok.Products.length == 4) ? `products-list__product-wrap products-list__product-wrap--small` : `products-list__product-wrap` }>
                    <Link key={index} to={`/${product.link.story.url}`} prefetch="true" className="product-link fade">
                      <Img loading="eager" className="cover-bg-img" fluid={
                        getFluidGatsbyImage(product.thumbnail, {
                          maxWidth: 900
                        })
                      } />
                      <div className="product-link__inner">
                        <div>
                          <p className="product-link__product-name" dangerouslySetInnerHTML={{ __html: product.title.replace('® ', '® <br/>') }}></p>
                          <p className="product-link__explore">Click to explore</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </SbEditable>

              ))}
            </div>
          </div>
          <div className="products-list-bg"></div>
        </section>
      </SbEditable>
    )
  }

}

export default Products
