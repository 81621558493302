import React from 'react'
import Teaser from './Teaser'
import HomepageVideoPanel from './HomepageVideoPanel'
import Grid from './Grid'
import Category from './Category'
import Product from './Product'
import Placeholder from './Placeholder'
import Hero from './Hero'
import TextBlock from './TextBlock'
import Features from './Features'
import ImageBlock from './ImageBlock'
import ImageAndTextBlock from './ImageAndTextBlock'
import Products from './Products'
import ZipThreeSixtyViewer from './ZipThreeSixtyViewer'
import FeatureTabs from './FeatureTabs'
import Video from './Video'
import Text from './Text'
import Title from './Title'
import Gif from './Gif'
import BulletPoint from './BulletPoint'
import Youtube from './Youtube'
import VideoPlayer from './VideoPlayer'
import TestimonialsRow from './TestimonialsRow'
import GalleryItem from './GalleryItem'

const Components = {
  'teaser': Teaser,
  'grid': Grid,
  'category': Category,
  'product': Product,
  'homepagevideopanel': HomepageVideoPanel,
  'Hero': Hero,
  'TextBlock': TextBlock,
  'ImageBlock': ImageBlock,
  'ImageAndTextBlock': ImageAndTextBlock,
  'Products': Products,
  'ZipThreeSixtyViewer': ZipThreeSixtyViewer,
  'Features': Features,
  'FeatureTabs': FeatureTabs,
  'Video': Video,
  'VideoPlayer': VideoPlayer,
  'TestimonialsRow': TestimonialsRow,
  'GalleryItem': GalleryItem,
  'Title': Title,
  'Text': Text,
  'Gif': Gif,
  'BulletPoint': BulletPoint,
  'Youtube': Youtube,
}

const Component = ({blok, loadingComplete, productID, renderVideoID}) => {
  if (typeof Components[blok.component] !== 'undefined') {
    const Component = Components[blok.component]
    return <Component blok={blok} loadingComplete={loadingComplete} productID={productID} renderVideoID={renderVideoID} />
  }
  return blok.component ? <Placeholder componentName={blok.component}/> : null
}

export default Component
