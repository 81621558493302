import React, { Component } from 'react'
import DynamicComponent from "./DynamicComponent"
import SbEditable from "storyblok-react"
import CloudinaryVideo from "./cloudinaryvideoembed"
import gridOfDots from "../images/grid-of-dots.svg"
import dashedLineVert from "../images/dashed-line-vert.svg"
// import Products from "./products"
import Footer from './Footer'
import Scrollbar from 'smooth-scrollbar'
import ScaleSpeedPlugin from './ScaleSpeedPlugin'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-storyblok-image'
import Link from 'gatsby-link'
import { isMobile, isTablet } from "react-device-detect"
import SampleButton from "./SampleButton"

let bodyScrollBar

class Product extends Component {
  constructor(props) {
    super(props)
    this.state = {
      scrollerInitialised: false,
      loading: true
    }
    this.scrollerRef = React.createRef();
    this.loadingComplete = this.loadingComplete.bind(this)

  }

  componentDidMount() {
    document.getElementById("main").style.display = "none"

    if(this.props.blok.LayoutBuilder[0]['Title'] == "UV RESISTANT") {
      this.loadingComplete()

      const scroller = this.scrollerRef.current
      if(!isMobile && !isTablet) {
        const scroller = document.querySelector('#scroller')

        bodyScrollBar = Scrollbar.init(scroller, {
          delegateTo: document,
          alwaysShowTracks: true,
          syncCallbacks: true
        })

        ScrollTrigger.scrollerProxy("#scroller", {
          scrollTop(value) {
            if (arguments.length) {
              bodyScrollBar.scrollTop = value;
            }
            return bodyScrollBar.scrollTop;
          }
        });
        bodyScrollBar.addListener(ScrollTrigger.update);
        ScrollTrigger.defaults({ scroller: scroller });


        gsap.utils.toArray(".row-text").forEach((textEl, i) => {
            gsap.from(textEl, {
              // clipPath:"inset(0% 0%)",
              opacity: 0,
              scrollTrigger: {
                trigger: textEl,
                start: "top 75%",
                // pin: true,
                end: () => "+=100",
                scrub: true,
                // markers: true,
                toggleActions: "play reverse play reverse"
              },
            });
        });

        gsap.utils.toArray(".row-title").forEach((titleEl, i) => {
          gsap.from(titleEl, {
            // clipPath:"inset(0% 0%)",
            opacity: 0,
            scrollTrigger: {
              trigger: titleEl,
              start: "top 85%",
              // pin: true,
              // pinSpacing: false,
              end: () => "+=150",
              scrub: true,
              // markers: true,
              toggleActions: "play reverse play reverse"
            },
          });
        });
        
        gsap.utils.toArray(".row-bullet-point").forEach((bullet, i) => {
          gsap.from(bullet, {
            clipPath:"inset(0% 100% 0% 0%)",
            opacity: 0,
            duration: 0.75,
            scrollTrigger: {
              trigger: bullet,
              start: "top 85%",
              // pin: true,
              // pinSpacing: false,
              // end: () => "+=50",
              // scrub: true,
              // markers: true,
              toggleActions: "play reverse play reverse"
            },
          });

        })

        gsap.utils.toArray(".product-gif").forEach((gif, i) => {
          gsap.set(gif, {clipPath:"inset(50% 0.5%)"});

          gsap.to(gif, {
            clipPath:"inset(0% 0.5%)",
            scrollTrigger: {
              trigger: gif,
              start: "top 55%",
              end: () => "+=100",
              scrub: true,
              // markers: true,
              toggleActions: "play reverse play reverse"
            },
          });

        })



        gsap.utils.toArray('.product-link').forEach(productLink => {
          gsap.from(productLink, {
            y: 125,
            opacity: 0,
            scrollTrigger: {
              trigger: productLink,
              start: "top 60%",
              toggleActions: "play reverse play reverse"
            },
          });
        });

        gsap.utils.toArray('.product-link img').forEach(productLinkImg => {
          gsap.from(productLinkImg, {
            clipPath:"inset(100% 0 0 0)",
            scrollTrigger: {
              trigger: productLinkImg,
              start: "top 80%",
              toggleActions: "play reverse play reverse"
            },
          });
        });


        gsap.utils.toArray('.product-link .product-link__product-name').forEach(productLinkTitle => {
          gsap.from(productLinkTitle, {
            clipPath:"inset(0 100% 0 0)",
            scrollTrigger: {
              trigger: productLinkTitle,
              start: "top 90%",
              toggleActions: "play reverse play reverse"
            },
          });
        });


        gsap.set('.outro-bg', {opacity: 0});
        gsap.to('.outro-bg', {
          opacity: 0.2,
          scrollTrigger: {
            trigger: '.outro-i',
            start: "top top",
            end: () => "+="+document.querySelector('.outro-ii').offsetHeight + document.querySelector('.products-list').offsetHeight,
            pin: true,
            pinSpacing: false,
          },
        });



        gsap.to('.outro-fore', {
          // clipPath:"inset(15% 5%)",
          clipPath:"inset(0% 0%)",
          scrollTrigger: {
            trigger: '.outro-fore',
            start: "top top+=150px",
            end: () => "+=500",
            scrub: true,
          },
        });

        gsap.to('.outro-fore', {
          opacity:.2,
          // clipPath:"inset(15% 5%)",
          scrollTrigger: {
            trigger: '.outro-ii.outro-panel .container',
            start: "top bottom-=200px",
            // pin: true,
            end: () => "+=250",
            scrub: true,
          },
        });
        gsap.set('.outro-fore', {clipPath:"inset(50% 0%)"});



      }


    }
    
    


      



        


    // }


  }

  loadingComplete() {
    document.getElementById("main").style.display = "block"
    this.setState({
      loading: false
    })
  }
  
  

  render() {
    const blok = this.props.blok
    console.log(blok.LayoutBuilder[0]['Title'])
    return (
      <SbEditable content={blok} key={blok._uid}>

        <div className={ (this.state.loading) ? 'loader' : 'loader loader--done' }></div>
        
        {(blok.bg_cloudinary_id) ?
          <div className="bg-video">
            <CloudinaryVideo
              publicId={`ykk/${blok.bg_cloudinary_id}`}
              class_list="cover-bg"
              autoPlay={true}
              darkenVideo={(blok.bg_cloudinary_id=="Home Page/1078141838-hd") ? true : false}
            />
          </div>
          : ''}

          <div id="scroller" ref={this.scrollerRef}>
            {
              blok.LayoutBuilder && blok.LayoutBuilder.map((childBlok) => {
                if(childBlok.component === "Hero") {
                  return (
                    <DynamicComponent 
                      blok={childBlok} 
                      key={childBlok._uid} 
                      productID={blok.ProductID} 
                      renderVideoID={blok.renderVideoID} 
                      loadingComplete={() => this.loadingComplete()} 
                    />
                  )
                }
                
                if(childBlok.component === "ZipThreeSixtyViewer" && blok.LayoutBuilder[0]['Title'] != "UV RESISTANT") {
                  console.log("!!!")
                  return (
                    <DynamicComponent 
                      blok={childBlok} 
                      key={childBlok._uid} 
                      productID={blok.ProductID} 
                      renderVideoID={blok.renderVideoID} 
                      loadingComplete={() => this.loadingComplete()} 
                    />
                  )
                }
              })
            }
            {
              (blok.BuckleImage) ? (
                <section className="zip-three-sixty-wrapper__outer zip-three-sixty-wrapper__outer--buckle">
                  <Img loading="eager" fluid={
                    getFluidGatsbyImage(blok.BuckleImage, {
                      maxWidth: 2000
                    })
                  } />

                  {
                    (blok.ProductID) ? (
                      <SampleButton productID={blok.ProductID} />
                    ) : ''
                  }

                  <img alt="Dots icon" aria-hidden={true} className="grid-of-dots zip-three-sixty-wrapper__grid-of-dots" src={gridOfDots} />
                  <img alt="Dots icon" aria-hidden={true} className="dashed-line-vert zip-three-sixty-wrapper__dashed-line-vert" src={dashedLineVert} />

                </section>
              ) : ''
            }

            <section className="outro-i">

              <Img loading="eager" className="cover-bg outro-bg" fluid={
                getFluidGatsbyImage(blok.DetailsBG, {
                  maxWidth: 2000
                })
              } />

              <div className="outro-i__one outro-fore">
                <Img loading="eager" className="cover-bg" fluid={
                  getFluidGatsbyImage(blok.DetailsBG, {
                    maxWidth: 2000
                  })
                } />
              </div>

            </section>
            <section className="outro-ii outro-panel">

              <div className="container container--x-slim">
                {
                  blok.Details && blok.Details.map((childBlok) => {
                    // console.log(childBlok);
                    return (
                      <DynamicComponent blok={childBlok} key={childBlok._uid} />
                    )
                  })
                }

                {
                  (blok.renderVideoID) ? (
                    <section className="" id="rendervideo">
                      <div className="video">
                        <CloudinaryVideo
                          publicId={`ykk/${blok.renderVideoID}`}
                          disableFill={true}
                          disableGif={true}
                          class_list="cover-bg"
                          autoPlay={false}
                          clickToPlay={true}
                        />
                      </div>
                    </section>
                  ) : ''
                }
              </div>

            </section>

            <section className="products-list products-list--fade">
              <div className="container container--slim">
                <div className="products-list__title">
                  <p className="h2">{ blok.RelatedTitle }</p>
                </div>
                <div className="products-list__grid">
                {blok.Products &&
                  blok.Products.map((product, index) => (
                    <SbEditable content={product} key={product._uid}>
                      <div className={ (blok.Products.length == 4) ? `products-list__product-wrap products-list__product-wrap--small` : `products-list__product-wrap` }>
                        <Link key={index} to={`/${product.link.story.url}`} prefetch="true" className="product-link fade">
                          <Img loading="eager" className="cover-bg-img" fluid={
                            getFluidGatsbyImage(product.thumbnail, {
                              maxWidth: 900
                            })
                          } />
                          <div className="product-link__inner">
                            <div>
                              <p className="product-link__product-name" dangerouslySetInnerHTML={{ __html: product.title.replace('® ', '® <br/>') }}></p>
                              <p className="product-link__explore">Click to explore</p>
                            </div>
                          </div>
                        </Link>
                      </div>
                    </SbEditable>

                  ))}
                </div>
              </div>
              <div className="products-list-bg"></div>
            </section>


            <Footer />
          </div>

      </SbEditable>
    )
  }
}

export default Product
