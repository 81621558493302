import React, { Component } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { Draggable } from "gsap/Draggable"
import { ScrollToPlugin } from "gsap/ScrollToPlugin"
import { InertiaPlugin } from "gsap/InertiaPlugin"
import Scrollbar from 'smooth-scrollbar'
import ScaleSpeedPlugin from './ScaleSpeedPlugin'
import { inRange } from 'lodash'
import gridOfDots from "../images/grid-of-dots.svg"
import dashedLineVert from "../images/dashed-line-vert.svg"
import threeSixtyIndicatorTl from "../images/three-sixty-indicator-tl.svg"
import zipIcon from "../images/zip-icon-real.png"
import zoomIcon from "../images/zoom-icon.svg"
import playIcon from "../images/play.svg"
import SampleButton from "./SampleButton"
//import { MapInteraction } from 'react-map-interaction'
import { MapInteractionCSS } from 'react-map-interaction';
import { isMobile, isTablet } from "react-device-detect"

let bodyScrollBar

let maxScroll

class ZipThreeSixtyViewer extends Component {
  constructor(props) {
    super(props)

    let bulletPositioningRef = {}
    let scrollBarHeight = 400

    props.blok.Bullets &&
      props.blok.Bullets.map((bullet, index) => {
        let offsetRatio = (Number.parseFloat(100 / props.blok.Bullets.length).toFixed(0)) / props.blok.Bullets.length
        let fixedPercentPosition = (Number.parseFloat(100 / props.blok.Bullets.length).toFixed(0)) * index
        let yMinPercent = fixedPercentPosition + offsetRatio
        let yMaxPercent = fixedPercentPosition + (offsetRatio * 2)
        let diff = (yMaxPercent - yMinPercent) * 0.5

        let yPixelPosition = (scrollBarHeight/100) * (yMinPercent + diff)

        bulletPositioningRef[index] = {
          'y': yPixelPosition,
          'yMid': (yMinPercent + diff),
          'yMin': yMinPercent,
          'yMax': yMaxPercent
        }

        
      }
    )

    this.state = {
      colourRgb: this.props.blok.Colours[0].RGB,
      folderId: this.props.blok.Colours[0].CloudinaryID,
      folderName: this.props.blok.Colours[0].FolderName,
      startFrame: 0,
      frameCount: 125,
      zipFrames: {
        frame: 0
      },
      scrollBarHeight: scrollBarHeight,
      images: [],
      bulletPositioningRef: bulletPositioningRef,
      showBullet: null,
      showZoomModal: false,
    }

    this.canvasRef = React.createRef();
    this.scrollBarRef = React.createRef();
    this.scrollBarWrapper = React.createRef();
    this.zoomedShotRef = React.createRef();
    this.modalEl = React.createRef();
    this.setupCanvas = this.setupCanvas.bind(this)
    this.setupScrollRevealElements = this.setupScrollRevealElements.bind(this)
    this.switchZipperColour = this.switchZipperColour.bind(this)
    this.pushimagesIntoState = this.pushimagesIntoState.bind(this)
    this.scrollToFeature = this.scrollToFeature.bind(this)
    this.scrollToRenderVideo = this.scrollToRenderVideo.bind(this)
    this.toggleFeatureBullet = this.toggleFeatureBullet.bind(this)
  }

  componentDidMount() {
    // this.setupCanvas()
    this.pushimagesIntoState(this.state.folderId, this.state.colourRgb, this.state.folderName)
  }

  componentWillUnmount() {
    console.log("componentWillUnmount 360 viewer")
  }

  switchZipperColour(folderId, colourRgb, folderName, zoomedShotSrc) {
    this.setState({
      colourRgb: colourRgb,
      images: []
    })

    this.zoomedShotRef.current.src = zoomedShotSrc

    this.pushimagesIntoState(folderId, colourRgb, folderName)
  }

  pushimagesIntoState(folderId, colourRgb, folderName) {
    let ctx = this.refs.canvas.getContext('2d');

    this.refs.canvas.width = 610;
    this.refs.canvas.height = 1080;

    let images = []
    const currentFrame = index => (
      `https://res.cloudinary.com/personatile/image/upload/f_auto,q_auto:eco${(folderName == "rds-products") ? ',e_replace_color:black:75,e_grayscale,e_brightness:-50' : '' }/${folderId}/ykk/360s/${folderName}/${colourRgb}/${(index + 1).toString().padStart(6, '0')}.png`
    );

    for (let i = this.state.startFrame; i < (this.state.frameCount + this.state.startFrame); i++) {
      const img = new Image();
      img.src = currentFrame(i);
      images.push(img);
    }

    this.setState({
      images: images
    })

    images[ this.state.zipFrames['frame'] ].onload = () => {
      ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
      ctx.drawImage(images[this.state.zipFrames.frame], 0, 0);
      this.props.loadingComplete()
      this.setupCanvas()
      this.setupScrollRevealElements()
      
    }
    
    // images[ (this.state.frameCount-2) ].onload = () => {
    images[ 14 ].onload = () => {
    // images[ 20 ].onload = () => {
      // this.props.loadingComplete()
      // this.setupCanvas()
      // this.setupScrollRevealElements()


    }

  }

  toggleFeatureBullet(progress, direction) {
    let progressPercentage = Math.round(progress * 100)
    let bulletInRange = false
    Object.entries(this.state.bulletPositioningRef).forEach(
        ([key, bulletPosition]) => {
          if(inRange(progressPercentage, bulletPosition.yMin, bulletPosition.yMax)) {
            bulletInRange = true
            this.setState({
              showBullet: key
            })
          }
        }
    );

    if(!bulletInRange) {
      this.setState({
        showBullet: null
      })
    }

  }

  scrollToFeature(scrollPos) {
    scrollPos = scrollPos + document.querySelector('.zip-three-sixty-wrapper').offsetHeight
    gsap.to(window, {duration: 0.5, scrollTo: scrollPos});
  }
  
  scrollToRenderVideo() {
    const rendervideo = document.querySelector('#rendervideo');
    const categoryIntro = document.querySelector('.category-intro');

    let renderVideoOffset = rendervideo.offsetTop + categoryIntro.offsetHeight + window.innerHeight; // get offset of rebnderVideo
    let newScrollPos = renderVideoOffset + document.querySelector('.zip-three-sixty-wrapper').offsetHeight + maxScroll

    bodyScrollBar.scrollTo(0, newScrollPos, 0);

  }

  setupCanvas() {
    console.log("setupCanvas")
    gsap.registerPlugin(ScrollTrigger);
    gsap.registerPlugin(InertiaPlugin);
    gsap.registerPlugin(Draggable);
    gsap.registerPlugin(ScrollToPlugin);
    const scroller = document.querySelector('#scroller')
    let ctx = this.refs.canvas.getContext('2d');
    

    // console.log(scroller)
    if(!isMobile && !isTablet) {

      bodyScrollBar = Scrollbar.init(scroller, {
        delegateTo: document,
        alwaysShowTracks: true,
        syncCallbacks: true
      })

      ScrollTrigger.scrollerProxy("#scroller", {
        scrollTop(value) {
          if (arguments.length) {
            bodyScrollBar.scrollTop = value;
          }
          return bodyScrollBar.scrollTop;
        }
      });

      bodyScrollBar.addListener(ScrollTrigger.update);
      bodyScrollBar.addListener(({ offset }) => {
        this.scrollBarWrapper.current.style.top = offset.y + 'px';
        this.modalEl.current.style.top = offset.y + 'px';
      });

      ScrollTrigger.defaults({ scroller: scroller });
    }






    

    


    let handler = document.querySelector("#handler"),
      barLength;

    let zipScroll = gsap.to(this.state.zipFrames, {
      frame: this.state.frameCount - 1,
      snap: "frame",
      scrollTrigger: {
        trigger: ".zip-three-sixty-wrapper",
        start: "top top",
        end: "+=6000", // slow down the time it takes to complete the anim
        pin: true,
        toggleClass: {targets: ".zip-three-sixty-wrapper__scroll-handler", className: "zip-three-sixty-wrapper__scroll-handler--active"},
        scrub: true,
        onUpdate: ({progress, direction}) => this.toggleFeatureBullet(progress, direction)
      },
      onUpdate: (progress, direction, isActive) => {
        if(this.refs.canvas) {
          ctx.clearRect(0, 0, this.refs.canvas.width, this.refs.canvas.height);
          ctx.drawImage(this.state.images[this.state.zipFrames.frame], 0, 0);
          gsap.set(handler, {y: barLength * ( zipScroll.scrollTrigger.scroll() - zipScroll.scrollTrigger.start ) / maxScroll});
        }
      },
      // onSnapComplete: ({progress, direction, isActive}) => console.log(progress, direction, isActive)
    });

    let scrollerProgressAnim = gsap.to('.draggable-progress', {
      scaleY: 0,
      scrollTrigger: {
        trigger: ".zip-three-sixty-wrapper",
        start: "top 0%",
        end: "+=9750", // slow down the time it takes to complete the anim
        scrub: true,
      }
    });

    barLength = 400;
    maxScroll = zipScroll.scrollTrigger.end - zipScroll.scrollTrigger.start;

    let snapPoints = [1, 399]

    Object.entries(this.state.bulletPositioningRef).forEach(
        ([key, bulletPosition]) => {
          snapPoints.splice(snapPoints.length-1, 0, bulletPosition.y)
        }
    );

    Draggable.create(handler, {
      type: "y",
      bounds: {minY: 1, maxY: 399},
      inertia: true,
      snap: snapPoints,
      onDrag: function() {
        zipScroll.scrollTrigger.scroll( zipScroll.scrollTrigger.start + (this.y / barLength * maxScroll) )
        scrollerProgressAnim.scrollTrigger.scroll( scrollerProgressAnim.scrollTrigger.start + (this.y / barLength * maxScroll) )
      },
      onDragStart: function() {
        this.target.classList.add('dragged')
      }
    });
    





  }

  setupScrollRevealElements() {
    if(!isMobile && !isTablet) {
        gsap.utils.toArray(".row-text").forEach((textEl, i) => {
          gsap.from(textEl, {
            // clipPath:"inset(0% 0%)",
            opacity: 0,
            scrollTrigger: {
              trigger: textEl,
              start: "top 75%",
              // pin: true,
              end: () => "+=100",
              scrub: true,
              // markers: true,
              toggleActions: "play reverse play reverse"
            },
          });
      });

      gsap.utils.toArray(".row-title").forEach((titleEl, i) => {
        gsap.from(titleEl, {
          // clipPath:"inset(0% 0%)",
          opacity: 0,
          scrollTrigger: {
            trigger: titleEl,
            start: "top 85%",
            // pin: true,
            // pinSpacing: false,
            end: () => "+=150",
            scrub: true,
            // markers: true,
            toggleActions: "play reverse play reverse"
          },
        });
      });
      
      gsap.utils.toArray(".row-bullet-point").forEach((bullet, i) => {
        gsap.from(bullet, {
          clipPath:"inset(0% 100% 0% 0%)",
          opacity: 0,
          duration: 0.75,
          scrollTrigger: {
            trigger: bullet,
            start: "top 85%",
            // pin: true,
            // pinSpacing: false,
            // end: () => "+=50",
            // scrub: true,
            // markers: true,
            toggleActions: "play reverse play reverse"
          },
        });

      })

      gsap.utils.toArray(".product-gif").forEach((gif, i) => {
        gsap.set(gif, {clipPath:"inset(50% 0.5%)"});

        gsap.to(gif, {
          clipPath:"inset(0% 0.5%)",
          scrollTrigger: {
            trigger: gif,
            start: "top 55%",
            end: () => "+=100",
            scrub: true,
            // markers: true,
            toggleActions: "play reverse play reverse"
          },
        });

      })



      gsap.utils.toArray('.product-link').forEach(productLink => {
        gsap.from(productLink, {
          y: 125,
          opacity: 0,
          scrollTrigger: {
            trigger: productLink,
            start: "top 60%",
            toggleActions: "play reverse play reverse"
          },
        });
      });

      gsap.utils.toArray('.product-link img').forEach(productLinkImg => {
        gsap.from(productLinkImg, {
          clipPath:"inset(100% 0 0 0)",
          scrollTrigger: {
            trigger: productLinkImg,
            start: "top 80%",
            toggleActions: "play reverse play reverse"
          },
        });
      });


      gsap.utils.toArray('.product-link .product-link__product-name').forEach(productLinkTitle => {
        gsap.from(productLinkTitle, {
          clipPath:"inset(0 100% 0 0)",
          scrollTrigger: {
            trigger: productLinkTitle,
            start: "top 90%",
            toggleActions: "play reverse play reverse"
          },
        });
      });
    }



    // gsap.to('.bg-video', {
    //   opacity: 0,
    //   scrollTrigger: {
    //     trigger: '.zip-three-sixty-wrapper__outer',
    //     start: "bottom 100%",
    //     end: () => "+=" + window.innerHeight,
    //     scrub: true,
    //     toggleActions: "play reverse play reverse"
    //   },
    // });

    gsap.set('.outro-bg', {opacity: 0});
    gsap.to('.outro-bg', {
      opacity: 0.2,
      scrollTrigger: {
        trigger: '.outro-i',
        start: "top top",
        end: () => "+="+document.querySelector('.outro-ii').offsetHeight + document.querySelector('.products-list').offsetHeight,
        pin: true,
        pinSpacing: false,
      },
    });



    gsap.to('.outro-fore', {
      // clipPath:"inset(15% 5%)",
      clipPath:"inset(0% 0%)",
      scrollTrigger: {
        trigger: '.outro-fore',
        start: "top top+=150px",
        end: () => "+=500",
        scrub: true,
      },
    });

    gsap.to('.outro-fore', {
      opacity:.2,
      // clipPath:"inset(15% 5%)",
      scrollTrigger: {
        trigger: '.outro-ii.outro-panel .container',
        start: "top bottom-=200px",
        // pin: true,
        end: () => "+=250",
        scrub: true,
      },
    });
    gsap.set('.outro-fore', {clipPath:"inset(50% 0%)"});
  }

  render() {
    return (
      <div className="zip-three-sixty-wrapper__outer">


        <div className="zip-three-sixty-wrapper__scroll-handler bar" ref={this.scrollBarWrapper}>
          <div id="handler" ref={this.scrollBarRef}>
            <img src={zipIcon} alt="zip icon" />
          </div>
          <div className="draggable-progress"></div>
          {
            this.props.blok.Bullets &&
              this.props.blok.Bullets.map((bullet, index) =>
                (
                  <span key={`scroll-marker-${bullet._uid}`} style={{top: `${this.state.bulletPositioningRef[index]['y'] + 40}px`}}></span>
                )
            )
          }
        </div>

        <div ref={this.modalEl} className={ (this.state.showZoomModal) ? 'modal modal--open' : 'modal' }>
          <div className="modal__flex">
            <div className="modal__inner modal__inner--black">
              <div className="modal__scroller">
                <MapInteractionCSS translationBounds={ {xMin: 0, xMax: 0, yMax: 0, yMin: -800} } minScale={1} maxScale={1}>
                  {
                    this.props.blok.Colours &&
                      this.props.blok.Colours.map((colour, index) =>
                        (
                          (index === 0) ?
                            <img ref={this.zoomedShotRef} className="zip-zoom" src={colour.ZoomedShot} />
                           : ''
                        )
                    )
                  }

                </MapInteractionCSS>
              </div>
              <button className="modal__close" onClick={ () => { this.setState({showZoomModal: false}) } }></button>
            </div>
          </div>
        </div>



        <div className={`zip-three-sixty-wrapper${(this.state.folderName == "cosmolon-disk") ? ' zip-three-sixty-wrapper--short' : '' }`}>



          <canvas id="zip-three-sixty-viewer" ref="canvas" />

          {/* <div className="zip-three-sixty-wrapper__colour-picker">
            
            <button className="zip-three-sixty-wrapper__view-detail-btn" onClick={ () => { this.setState({showZoomModal: true}) } }>
              <span>View 3D Render</span>
              <div className="request-a-sample-btn__icon">
                <img alt="Zoom icon" src={zoomIcon} />
                <svg width="52px" height="52px" viewBox="0 0 52 52">
                  <path className="zipstroke" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                  <path className="zipdashes" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                </svg>
              </div>
            </button>

            {
              (this.props.blok.Colours.length > 1) ? (
                <span>Pick a colour</span>
              ) : ''
            }
            {
              this.props.blok.Colours.length > 1 &&
                this.props.blok.Colours.map((colour, index) =>
                  (
                    <button key={colour._uid} className={ (this.state.colourRgb == colour.RGB) ? 'active' : '' } aria-label="Change colour" style={{backgroundColor: `rgb(${colour.RGB})`}} onClick={ () => { this.switchZipperColour(colour.CloudinaryID, colour.RGB, colour.FolderName, colour.ZoomedShot)} }></button>

                  )
              )
            }
          </div> */}
          
          {
            (this.props.renderVideoID) ? (
              <button className="zip-three-sixty-wrapper__view-detail-btn zip-three-sixty-wrapper__view-detail-btn--view-render" onClick={ () => { this.scrollToRenderVideo() } }>
                View product video
                <div className="request-a-sample-btn__icon">
                  <img className="play-icon" alt="Play icon" src={playIcon} />
                  <svg width="52px" height="52px" viewBox="0 0 52 52">
                    <path className="zipstroke" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                    <path className="zipdashes" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                  </svg>
                </div>
              </button>
            ) : ''
          }
          
          
          
          {
            (this.props.blok.Colours[0].ZoomedShot) ?
              (
                <button className="zip-three-sixty-wrapper__view-detail-btn" onClick={ () => { this.setState({showZoomModal: true}) } }>
                  Click to zoom
                  <div className="request-a-sample-btn__icon">
                    <img alt="Zoom icon" src={zoomIcon} />
                    <svg width="52px" height="52px" viewBox="0 0 52 52">
                      <path className="zipstroke" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                      <path className="zipdashes" fill="none" stroke="white" strokeWidth="1.5" d="M26,51C12.2,51,1,39.8,1,26S12.2,1,26,1s25,11.2,25,25S39.8,51,26,51z"/>
                    </svg>
                  </div>
                </button>
              )
            : ''
          }
          

          {
            (this.props.productID) ? (
              <SampleButton productID={this.props.productID} />
            ) : ''
          }


          {
            this.props.blok.Bullets &&
              this.props.blok.Bullets.map((bullet, index) =>
                (
                  <i key={bullet._uid} className={ (this.state.showBullet == index) ? 'active' : '' } onClick={ () => this.scrollToFeature(450) }><span className={ (bullet.align) ? bullet.align : '' }>{bullet.bullet}</span><img src={threeSixtyIndicatorTl} alt="line"/></i>
                )
            )
          }

          <div className="zip-three-sixty-wrapper__mobile-bullets">
          {
            this.props.blok.Bullets &&
              this.props.blok.Bullets.map((bullet, index) =>
                (
                  <i key={bullet._uid} className={ (this.state.showBullet == index) ? 'active' : '' }><span>{bullet.bullet}</span></i>
                )
            )
          }
          </div>


          <img alt="Dots icon" aria-hidden={true} className="grid-of-dots zip-three-sixty-wrapper__grid-of-dots" src={gridOfDots} />
          <img alt="Dots icon" aria-hidden={true} className="dashed-line-vert zip-three-sixty-wrapper__dashed-line-vert" src={dashedLineVert} />

        </div>
      </div>
    )
  }


}

export default ZipThreeSixtyViewer
